<!--  -->
<template>
  <div class="">
    <el-table
      ref="tablePanel"
      :data="dataList"
      :border="true"
      @cell-mouse-enter="cellMouseEnter"
      @cell-mouse-leave="cellMouseLeave"
      style="{
        width: '100%',
        'min-height': '0px',
        'max-height': '1500px',
        overflow: 'auto',
      }"
    >
      <!-- 表头&内容展示区 -->
      <template v-for="(item, itemIndex) in tableAttr">
        <slot :name="item.schemefieldName" :item="itemIndex">
          <!-- 普通文本类型数据 -->
          <el-table-column>
            <template slot="header">
              <!-- 普通自定义表头 -->
              <div class="tableHeadField">
                <el-popover
                  placement="top-start"
                  trigger="hover"
                  popper-class="my-tooltip table-head-tooltip"
                  :content="item.schemefieldName"
                >
                  <span slot="reference">
                    {{ item.schemefieldName }}
                  </span>
                </el-popover>
              </div>
              <!-- 功能性自定义表头 -->
            </template>
            <template slot-scope="scope">
              <!-- 日期 -->
              <!-- <span class="cannotEdit" v-if="item.schemefieldType === 'D'">
                {{ scope.row[item.schemefieldName] | dateFormat(countryCode) }}
              </span> -->
              <!-- 日期时间 -->
              <!-- <span class="cannotEdit" v-else-if="item.schemefieldType === 'F'">
                {{
                  scope.row[item.schemefieldName] | datetimeFormat(countryCode)
                }}
              </span> -->
              <!-- 记录类型 -->
              <!-- <span class="cannotEdit" v-else-if="item.schemefieldType === 'R'">
                {{ scope.row[`${item.schemefieldName}ccname`] }}
              </span> -->
              <!-- 日期web复合类型 -->
              <!-- <span
                class="cannotEdit"
                v-else-if="item.schemefieldType === 'DE'"
              >
                <div class="compositeDate">
                  {{ scope.row[item.schemefieldName].date }}
                </div>
                <div class="composite">
                  {{ scope.row[item.schemefieldName].web }}
                </div>
                <div class="composite">
                  {{ scope.row[item.schemefieldName].time }}
                </div>
              </span> -->
              <!-- 图片文字复合类型 -->
              <span v-if="item.schemefieldType === 'S' && scope.row[item.key]">
                <div>
                  {{ scope.row[item.key] }}
                </div>
              </span>
              <!--  百分比类型 -->
              <span v-if="item.schemefieldType === 'PE'">
                <div class="percentageNB">
                  {{ scope.row[item.key] }}
                </div>
                <div style="width: 85%">
                  <div
                    class="percentage"
                    :style="{ width: scope.row.submissionRate }"
                  ></div>
                </div>
              </span>
              <!-- 普通文本 -->
              <span v-if="item.schemefieldType === 'L'">
                {{ scope.row[item.key] }}
              </span>
              <!--事件及任务下的名称及相关项去除编辑/相关列表文件、类型attachtype去除编辑-->
            </template>
          </el-table-column>
        </slot>
      </template>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'tablepanel',
  components: {},
  props: {
    dataList: {
      type: Array,
      default:() => [],
    },
    tableAttr: {
      type: Array,
      default:() => [],
    },
  },
  data() {
    return {
      visibleChangeFlg: false,
      selectNumber: 0,

      selectvaue1: [
        'View',
        'Details',
        'Share',
        'View submissions',
        'Export submission',
        'Create list of contacts',
      ],
      selectvaue2: ['Rename'],
      checkboxSelect: [],

      nameLableList: [],

      formshow: false,
    }
  },
  created() {
    // this.nameLableList = [];
    // this.tableAttr.forEach((item) => {
    //   this.nameLableList.push(item.nameLabel);
    // });
  },
  mounted() {
    // this.changeTableAttr()
  },
  //方法集合
  methods: {
    // imgScreen(editer) {
    //   let img = []
    //   if (editer.includes('<img')) {
    //     img = editer.match(/<img[^>]+>/g); // 利用正则，取出所有img标签，数据格式为数组
    //     let arrImg = ""; // 定义空字符串，下面会往里边填充img标签
    //     for (let j = 0; j < img.length; j++) {
    //       // 正则匹配，摘出img标签下的src里的内容，即capture
    //       img[j].replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, function (match, capture) {
    //         // arrImg += '<div class="img-box" style="background: url(\'' + capture + '\') no-repeat center / cover;width:30.3333%;height:100px;float:left;margin: 0 1.5% 10px 1.5%;" ></div>';
    //         arrImg = capture; //这快就可以拼接想要的dom
    //       });
    //       // 这边为了不让九宫格里的图片压缩，所以放置在div的背景里
    //     }
    //     return arrImg
    //   } else {
    //     return 
    //   }
    // },
    // // 去除富文本表图片
    // screen(editer) {
    //   return editer.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, '').replace(/&nbsp;/ig, '')
    // },
    // actionEmit(item) {
    //   this.$emit(item.emit, item.row.row)
    // },
    // visibleChange(flag) {
    //   this.visibleChangeFlg = flag
    // },
    // postAssgin() {
    //   this.$emit('postAssgin', this.selection)
    // },
    // postClone() {
    //   this.$emit('postClone', this.selection)
    // },
    // selectAll(selection) {
    //   this.selection = selection
    //   this.selectNumber = selection.length
    // },
    // select(selection, row) {
    //   this.selection = selection
    //   this.selectNumber = selection.length
    // },
    // rowoptionshow(even, item) {
    //   even.optionshow = false;
    //   if (item == "Details") {
    //     this.$router.push({
    //       name: 'detail',
    //       params: {
    //         id: '3',
    //       }
    //     })
    //   };
    // },
    // //表格划入
    // cellMouseEnter(row, column, cell, event) {
    //   // 显示 操作按钮
    //   // if (cell.getElementsByClassName('btList-box')) {
    //   //   cell.getElementsByClassName('btList-box')[0].style.display = 'block'
    //   //   cell.getElementsByClassName('imgBox')[0].style.display = 'none'

    //   // }

    // },
    // //表格滑出
    // cellMouseLeave(row, column, cell, event) {
    //   // 隐藏 操作按钮
    //   // 
    //   // if (cell.getElementsByClassName('btList-box')) {
    //   //   cell.getElementsByClassName('btList-box')[0].style.display = 'none'
    //   //   cell.getElementsByClassName('imgBox')[0].style.display = 'block'
    //   // }

    //   // if (!this.visibleChangeFlg) {
    //   //   for (let i = 0; i < this.dataList.length; i++) {
    //   //     this.dataList[i].vshow = false
    //   //   }
    //   // }
    // },
    // cloneformevent(key) {
    //   this.Cloneform = true;
    //   this.Cloneformvalue = key.name + "(" + key.Last + ")"
    // },
    // action(key) {
    //   key.optionshow = true;
    //   this.deletetitle = key.name + "(" + key.Last + ")"
    // },
    //强制刷新
    // changeTableAttr(tableAttr) {
    //   this.nameLableList = [];
    //   tableAttr.forEach((item) => {
    //     this.nameLableList.push(item.nameLabel);
    //   });
    //   this.$nextTick(() => {
    //     this.$refs.tablePanel.doLayout();
    //     this.$forceUpdate();
    //   });
    // },
    // //百分比规格
    format(point) {
      if (point.indexOf('%') > 0) {
        return point
      } else {
        const formted = Number(point * 100).toFixed(2)
        return `${formted}%`
      }
    },
    getrid(item) {
      if (item.indexOf('%') > 0) {
        let ite = item.substring(0, item.length - 1)
        let it = Number(ite / 100).toFixed(2)
        return it
      } else {
        return item
      }
    },
  },
}
</script>
<style lang='scss' scoped>
.icon {
  display: inline-block;
  margin-left: 4px;
}
.pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
.functional-box {
  display: flex;
  align-items: center;
  .statistical {
    font-family: Helvetica;
    font-size: 14px;
    color: #3e3e3c;
    letter-spacing: 0;
  }
  .clone-img {
    margin-left: 15px;
    margin-top: 2px;
  }
  .editpen-box {
    margin-top: 2px;
    margin-left: 10px;
    width: 10px;
    height: 10px;
  }
  .assign-box {
    margin-left: 10px;
    font-family: Helvetica;
    font-size: 14px;
    color: #2D6CFC;
    letter-spacing: 0;
  }
  .clone-box {
    margin-left: 10px;
    font-family: Helvetica;
    font-size: 14px;
    color: #2D6CFC;
    letter-spacing: 0;
  }
}
.compositeDate {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #3e3e3c;
  letter-spacing: 0;
}
.composite {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #888888;
  letter-spacing: 0;
}
.tablenamesecond {
  button {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #080707;
    letter-spacing: 0;
    background: #ffffff;
    border: 1px solid #dedcda;
    border-radius: 3px;
    margin-right: 10px;
    height: 30px;
    cursor: pointer;
  }
  .actionselect {
    background: #ffffff;
    border: 1px solid #dedcda;
    border-radius: 3px;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #080707;
    letter-spacing: 0;
    height: 30px;
    .borderli {
      width: 100%;
      height: 1px;
      background: #dedcda;
      margin-top: 3px;
      margin-bottom: 3px;
    }
    .actionoption {
      position: absolute;
      // right: -90px;
      // top: 50px;
      right: -30px;
      bottom: 5px;
      background: #fff;
      z-index: 9999;
      width: 190px;
      background: #ffffff;
      border: 1px solid #dddbda;
      box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.46);
      li {
        line-height: 32px;
        text-align: left;
        padding-left: 8px;
      }
      li:hover {
        background: #ececec;
        color: #2D6CFC;
      }
    }
    .actiontop {
      position: absolute;
      // right: -50px;
      // bottom: 50px;
      right: -30px;
      bottom: 5px;
      background: #fff;
      z-index: 9999;
      width: 190px;
      background: #ffffff;
      border: 1px solid #dddbda;
      box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.46);
      li {
        line-height: 32px;
        text-align: left;
        padding-left: 8px;
      }
      li:hover {
        background: #ececec;
        color: #2D6CFC;
      }
    }
  }
}
.postImg {
  // display: inline-block;
  // vertical-align: middle;

  min-height: 150px;
  overflow: hidden;
  display: flex;
  align-items: center;
  max-height: 200px;
  .logoBox {
    width: 40px;
  }
  .message {
    width: 320px;
  }
  .imgBox {
    margin-left: 50px;
    .pictureTips {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #3e3e3c;
      letter-spacing: 0;
    }
  }
  display: flex;
  align-items: center;
}
.content-main {
  position: relative;
  clear: both;
}

.showEditTableField-box {
  position: absolute;
  top: 11px;
  left: 18px;
  z-index: 9999;
}

.action-btn {
  color: #2D6CFC !important;
}

// 评分字段显示
.stars {
  list-style: none;
  margin: 0;
  word-break: break-all;
  color: #ccc;
  float: left;
  display: contents;
}

.stars span {
  font-size: 20px;
  margin-left: 3px;
}

.stars span:after {
  content: "☆";
}

.stars .show:after,
.stars .show2:after {
  content: "★";
}

.show2 {
  color: #f7ba2a;
}

.table-head-tool {
  .el-input {
    position: absolute;
    top: -3px;
    left: -10px;
    margin-left: 10px;
    // width: calc(100% - 40px);
  }

  ::v-deep .el-input__inner {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    height: 25px;
    padding-left: 0;
  }

  .dragBox {
    width: 49%;
  }

  .item-box-left-container {
    border: 1px solid #ddd;
    border-radius: 4px;
    min-height: 250px;
    max-height: 250px;

    .searchBox {
      position: relative;
      padding: 0 10px;
      top: 5px;
      height: 25px;
      margin-bottom: 0;

      i {
        position: absolute;
        z-index: 9999;
      }

      ::v-deep .el-input__inner {
        padding-left: 32px;
      }
    }
  }

  .itemBox {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 5px 0;
    min-height: 250px;
    max-height: 250px;
    overflow: auto;
  }

  .itemBox-left {
    border: none;
    min-height: 200px;
    max-height: 222px;
    margin-top: 2px;
  }

  .dragItem {
    width: 100%;
    height: 30px;
    display: inline-block;
    line-height: 30px;
    padding: 0 10px;
    cursor: move;

    &:hover {
      background: #fafaf9;
    }
  }

  .btns {
    width: 95%;
    margin: 10px 15px 10px 0;
    text-align: right;
    position: absolute;
    bottom: 0;
  }
}

// 进度条样式修改
::v-deep .el-progress-bar {
  padding-right: 14px;
}

.fileItem {
  color: #489ad9;
  cursor: pointer;
  margin-right: 5px;
}

::v-deep .el-dialog__header {
  text-align: left;
}

::v-deep .el-table-column--selection .cell {
  text-align: center;
}

// ::v-deep .el-table__fixed-body-wrapper {
//   // top: 48px !important;
// }

::v-deep .el-dialog__footer {
  clear: both;
}

// 表格纵向滚动条
::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow: auto;
}

.tableHeadField {
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
  text-overflow: ellipsis;

  .popover-span {
    position: absolute;
    right: 0;
  }
}

// 提示语
.noDataTip {
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
  background: #fff;
}

// 表头样式
.el-table--border,
.el-table--group {
  border: 1px solid #dedcda;
}

::v-deep .el-table th.is-leaf {
  color: #333;
  border-bottom: 1px solid #dedcda;
}

::v-deep .el-table--border th {
  border-right: 1px solid #dedcda;
}

::v-deep .el-table__header tr th {
  padding: 8px;
  padding-left: 0;
  padding-right: 0;
}

// 标签样式
.tag {
  font-size: 12px;
  padding: 5px;
  height: 25px;
  line-height: 15px;
  margin-right: 5px;
}

::v-deep .el-tag--dark {
  background-color: #62a5f3;
  border-color: #62a5f3;
}

::v-deep .el-tag--dark.el-tag--info {
  background-color: #dedcda;
  border-color: #dedcda;
}

::v-deep .el-tag--dark.el-tag--danger {
  background-color: #f6817b;
  border-color: #f6817b;
}

::v-deep .el-tag--dark.el-tag--success {
  background-color: #7cedcc;
  border-color: #7cedcc;
}

::v-deep .el-tag--dark.el-tag--warning {
  background-color: #fec366;
  border-color: #fec366;
}

// 进度条样式
.el-progress {
  display: inline;
}

::v-deep .el-table td .cell {
  padding-right: 20px;
}

::v-deep .el-table td:first-child .cell {
  padding-right: 14px;
}

// 表头颜色自定义、单元格超出...显示
::v-deep .el-table__header {
  img {
    margin-top: -4px;
  }

  tr th {
    background: #fafaf9;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th .cell {
    padding-right: 14px;

    .el-checkbox {
      margin-left: 0;
    }
  }
}

.admin_s {
  margin-top: -4px;
  cursor: pointer;
  width: 14px;
}

::v-deep .el-table .cell {
  min-height: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  // padding-right: 20px;
}

// #table-panel .content-main .el-table {
//   tr th {
//     background: #2D6CFC;
//   }
// }

::v-deep .el-table td {
  position: relative;
  padding: 9px 0;
  color: #080707;
  border-bottom: 1px solid #dedcda;
  word-break: break-all;
}

::v-deep .el-table--border td {
  border-right: none;
}

.allow-click {
  color: #2D6CFC;
  text-decoration: solid;
}
::v-deep .is-checked {
  .el-checkbox__input {
    .el-checkbox__inner {
      background-color: #2D6CFC !important;
      border-color: #2D6CFC !important;
    }
  }
}
::v-deep .el-checkbox {
  .is-indeterminate {
    .el-checkbox__inner {
      background-color: #2D6CFC !important;
      border-color: #2D6CFC !important;
    }
  }
}
</style>
<style scoped>
.textpal {
  cursor: pointer;
}
.pop_small ul {
  margin: 0 -12px;
  min-height: 26px;
}

.pop_small li {
  min-height: 26px;
  padding-right: 10px;
  font-size: 12px;
  line-height: 26px;
  cursor: pointer;
  color: #3e3e3c;
}
.pop_small li span {
  padding-left: 10px;
}
.pop_small li:hover {
  background: #f3f2f2;
  color: #2D6CFC;
}
.pop_small ul {
  margin: 0 -12px;
  min-height: 26px;
}

.pop_small li {
  min-height: 26px;
  padding-right: 10px;
  font-size: 12px;
  line-height: 26px;
  cursor: pointer;
  color: #3e3e3c;
}
.pop_small li span {
  padding-left: 10px;
}
.pop_small li:hover {
  background: #f3f2f2;
  color: #2D6CFC;
}
.percentageNB {
  float: left;
  width: 15%;
}
.percentage {
  background-color: #fea58e;
  background: #fea58e;
  border-radius: 2px;
  border-radius: 2px;
  height: 20px;
  display: inline-block;
}
</style>
